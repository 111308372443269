import { ApolloClient } from '@apollo/client'

import smsTemplates from '@/graphql/templates-new/smsTemplates.gql'
import smsTemplateUpdate from '@/graphql/templates/smsTemplateUpdate.gql'
import smsTemplateCreate from '@/graphql/templates/smsTemplateCreate.gql'
import smsTemplateDelete from '@/graphql/templates/smsTemplateDelete.gql'

import type { ApolloContext } from '@/services/types/Gql'
import type { Pagination } from '@/services/types/Pagination'
import type { SmsTemplateListType, SmsTemplateUpdate, SmsTemplateCreate, SmsTemplateDelete } from '@/types/generated'

// TODO: move this inside a helper file
const getLimitAndOffset = (pagination: Pagination) => ({
  limit: pagination ? pagination.itemsPerPage : null,
  offset: pagination ? pagination.itemsPerPage * (pagination.page - 1) : null
})

interface ITemplatesNewGql {
  $apollo: ApolloClient<any>
  smsTemplates: (
    tenantId: string,
    pagination: Pagination,
    ordering: string,
    search: string,
    shared: boolean,
    service: string,
    status: string,
  ) => Promise<SmsTemplateListType>
  smsTemplateCreate: (
    name: string,
    phoneNumberId: string,
    template: string,
    service: string,
    tenantId: string,
    shared: boolean
  ) => Promise<SmsTemplateCreate>
  smsTemplateUpdate: (
    name: string,
    template: string,
    tenantId: string,
    tempalteId: string
  ) => Promise<SmsTemplateUpdate>
  smsTemplateDelete: (
    templateId: string,
    tenantId: string
  ) => Promise<SmsTemplateDelete>
}

export default class TemplatesNewGql implements ITemplatesNewGql {
  $apollo: ApolloClient<any>
  constructor (context: ApolloContext) {
    this.$apollo = context.$apollo
  }

  async smsTemplates (
    tenantId: string,
    pagination: Pagination,
    ordering: string,
    search: string,
    shared = false,
    service: string,
    status: string
  ) {
    const { limit, offset } = getLimitAndOffset(pagination)
    return await this.$apollo.query({
      query: smsTemplates,
      fetchPolicy: 'no-cache',
      variables: { tenantId, limit, offset, ordering, search, shared, service, status }
    }).then(res => res?.data.smsTemplates as SmsTemplateListType)
  }

  async smsTemplateCreate (
    name: string,
    phoneNumberId: string,
    template: string,
    service: string,
    tenantId: string,
    shared = false
  ): Promise<SmsTemplateCreate> {
    return await this.$apollo.mutate({
      mutation: smsTemplateCreate,
      variables: {
        name,
        phoneNumberId,
        template,
        service,
        tenantId,
        shared
      }
    }).then(result => result.data.smsTemplateCreate)
  }

  async smsTemplateUpdate (
    name: string,
    template: string,
    tenantId: string,
    templateId: string
  ): Promise<SmsTemplateUpdate> {
    return await this.$apollo.mutate({
      mutation: smsTemplateUpdate,
      variables: {
        name,
        template,
        tenantId,
        templateId
      }
    }).then(result => result.data.smsTemplateUpdate)
  }

  async smsTemplateDelete (templateId: string, tenantId: string): Promise<SmsTemplateDelete> {
    return await this.$apollo.mutate({
      mutation: smsTemplateDelete,
      variables: {
        id: templateId,
        tenantId
      }
    }).then(result => result.data.smsTemplateDelete)
  }
}
